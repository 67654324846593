// import react from "react";
import Navbar from "../components/Navbar/Navbar";

function Contact() {
  return (
    <div className="">
      <Navbar />
    </div>
  );
}

export default Contact;
